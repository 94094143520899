import {call, put, takeLatest, all, fork} from 'redux-saga/effects';
import {favoriteItemAPI, fetchFavoriteItemIDsAPI, fetchFavoriteItemsAPI} from "./service";

// favorite item
function* toggleFavorite({ payload }) {
    const response = yield call(favoriteItemAPI, payload);
    yield put({ type: "TOGGLE_FAVORITE_SUCCESS"});
    // }catch(e) {
    //     console.log("favorites result error");
    //     yield put({ type: "FAVORITES_RESULTS_ERROR", payload: e.message })
    // }
}
function* watchToggleFavorite() {
    yield takeLatest('TOGGLE_FAVORITE', toggleFavorite)
}

// get favorites
function* fetchFavorites() {
    const response = yield call(fetchFavoriteItemsAPI);
    yield put({ type: "FAVORITE_ITEMS_RECEIVED", favorites: response.data.deals});
    // }catch(e) {
    //     console.log("favorites result error");
    //     yield put({ type: "FAVORITES_RESULTS_ERROR", payload: e.message })
    // }
}
function* watchFetchFavoriteItems() {
    yield takeLatest('FETCH_FAVORITE_ITEMS', fetchFavorites)
}

// get favorite IDs
function* fetchFavoriteItemIDs() {
    const response = yield call(fetchFavoriteItemIDsAPI);
    yield put({ type: "FAVORITE_ITEM_IDS_RECEIVED", favorite_ids: response.data});
    // }catch(e) {
    //     console.log("favorites result error");
    //     yield put({ type: "FAVORITES_RESULTS_ERROR", payload: e.message })
    // }
}
function* watchFetchFavoriteItemIDs() {
    yield takeLatest('FETCH_FAVORITE_ITEM_IDS', fetchFavoriteItemIDs)
}

export default function* rootSaga() {
    yield all([
        fork(watchToggleFavorite),
        fork(watchFetchFavoriteItems),
        fork(watchFetchFavoriteItemIDs),
    ]);
}