import axios from "axios";

export function fetchSearchResultsAPI({searchTerm, page}) {
    return axios.post(`https://mishto.herokuapp.com/v2/searches`, {
    userId: "general_search",
    search: searchTerm,
    price: "300",
    page: page,
    });
}

export function fetchSavedSearchesAPI() {
    return axios.post(`https://mishto.herokuapp.com/searches/retrieve`, {
        userId: localStorage.getItem('user_id')
    });
}

export function removeSavedSearchAPI(savedSearchId) {
    axios.post(`https://mishto.herokuapp.com/searches/delete`, {
        searchId: savedSearchId,
        userId: localStorage.getItem('user_id')
    });
}

export function createSavedSearchAPI({search, maxPrice}) {
    axios.post(`https://mishto.herokuapp.com/searches`, {
        userId: localStorage.getItem('user_id'),
        search: search,
        price: maxPrice
    });
}