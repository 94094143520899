export const toggleFavorite = (itemId) => ({
    type: 'TOGGLE_FAVORITE',
    payload: itemId,
});

export const fetchFavorites = () => ({
    type: 'FETCH_FAVORITE_ITEMS',
});

export const fetchFavoriteItemIDs = () => ({
    type: 'FETCH_FAVORITE_ITEM_IDS',
});