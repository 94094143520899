import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Breadcrumb from "../../common/breadcrumb";
import Filter from "../../collection/common/filter";
import FilterBar from "../../collection/common/filter-bar";
import ProductListing from "../../collection/common/product-listing";
import SaveSearchHeader from "../../collection/common/save-search-header";
import StickyBox from "react-sticky-box";
import Loading from "../../layouts/mishto/loading";
import {getBrands, getColors, getMinMaxPrice} from "../../../services";
import {connect} from "react-redux";
import {filterBrand, filterColor, filterPrice} from "../../../actions";
import {fetchFavoriteItemIDs} from "../../../redux/deals/favorites/actions";

class ProductListLeftSidebar extends Component {

    state = {
        layoutColumns:3
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    componentDidMount() {
        this.props.fetchFavoriteItemIDs()
    }

    render (){
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>{`Shop Mishto | ${this.props.searchTerm}`} </title>
                    <meta name="description" content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses." />
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={this.props.searchTerm} parent="Search"/>

                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                {/*<div className="col-sm-3 collection-filter">*/}

                                {/*    <StickyBox offsetTop={20} offsetBottom={20}>*/}
                                {/*        <div>*/}
                                {/*            <Filter/>*/}
                                {/*        </div>*/}
                                {/*    </StickyBox>*/}
                                {/*    /!*side-bar banner end here*!/*/}
                                {/*</div>*/}
                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="collection-product-wrapper">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <SaveSearchHeader />
                                                            </div>
                                                        </div>
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-xl-12">
                                                                        <div className="filter-main-btn">
                                                                            <span onClick={this.openFilter}
                                                                                  className="filter-btn btn btn-theme"><i
                                                                                className="fa fa-filter"
                                                                                aria-hidden="true"></i> Filter</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        {/*<FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>*/}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Products Listing Component*/}
                                                        <Loading />
                                                        <ProductListing colSize={this.state.layoutColumns}/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    brands: getBrands(state.data.products),
    colors: getColors(state.data.products),
    prices: getMinMaxPrice(state.data.products),
    filters: state.filters,
    searchTerm: state.deals.searchTerm
})

export default connect(
    mapStateToProps,
    { filterBrand, filterColor, filterPrice, fetchFavoriteItemIDs }
)(ProductListLeftSidebar);