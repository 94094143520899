import React, {Component} from 'react';

import Breadcrumb from "../common/breadcrumb";
import axios from "axios/index";
import { connect } from "react-redux";
import { registerUser } from "../../redux/actions";



class Register extends Component {

    constructor (props) {
        super (props)
        this.state = {
            email: "",
            password: "",
            firstName: "",
            lastName: ""
        };
    }

    onUserRegister() {
        console.log("register state:" + this.state);
        if (this.state.email !== "" && this.state.password !== "") {
            localStorage.setItem('email', this.state.email);
            localStorage.setItem('first_name', this.state.firstName);
            localStorage.setItem('last_name', this.state.lastName);
            this.props.registerUser(this.state, this.props.history);
        }

    }

    render (){


        return (
            <div>
                <Breadcrumb title={'create account'}/>
                
                
                {/*Regsiter section*/}
                <section className="register-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h3>create account</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">First Name</label>
                                                <input type="text" className="form-control" id="fname"
                                                       placeholder="First Name" required=""
                                                       onChange={e => this.setState({firstName: e.target.value})}/>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Last Name</label>
                                                <input type="password" className="form-control" id="lname"
                                                       placeholder="Last Name" required=""
                                                       onChange={e => this.setState({lastName: e.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <label htmlFor="email">email</label>
                                                <input type="text" className="form-control" id="email"
                                                       placeholder="Email" required=""
                                                       onChange={e => this.setState({email: e.target.value})}/>
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="review">Password</label>
                                                <input type="password" className="form-control" id="review"
                                                       placeholder="Enter your password" required=""
                                                       onChange={e => this.setState({password: e.target.value})}/>
                                            </div>
                                            <a onClick={() => this.onUserRegister()} className="btn btn-solid">create Account</a>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = ({authUser}) => {
    const { user, loading } = authUser;
    return { user, loading };
};

export default connect(
    mapStateToProps,
    {
        registerUser
    }
)(Register);
