import React, {Component} from 'react';
import {connect} from "react-redux";

class  AccountInfo extends Component {

    constructor(){
        super();
        this.state = {}

    }

    render(){
        return(
            <div className="box-account box-info">
                <div className="box-head">
                    <h2>Account Information</h2>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <div className="box">
                            <div className="box-title">
                                <h3>Contact Information</h3>
                                <a href="#">Edit</a>
                            </div>
                            <div className="box-content">
                                <h6>{this.props.userInfo.first_name} {this.props.userInfo.last_name}</h6>
                                <h6>{this.props.user.user.email}</h6>
                                <h6><a href="#">Change Password</a></h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="box">
                            <div className="box-title">
                                <h3>Newsletters</h3>
                                <a href="#">Edit</a>
                            </div>
                            <div className="box-content">
                                <p>
                                    You are currently not subscribed to any newsletter.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ authUser }) => {
    const { user, userInfo } = authUser;
    return { user, userInfo };
};

export default connect(
    mapStateToProps, {}
)(AccountInfo)