import React, {Component} from 'react';
import {connect} from 'react-redux';
import { createSavedSearch } from '../../../redux/deals/search/actions';


class SaveSearchHeader extends Component {

    constructor(){
        super();
        this.state = {
            searchSaved: false,
        };
        this.handleSaveSearch = this.handleSaveSearch.bind(this);
        this.renderSaveSearchButton = this.renderSaveSearchButton.bind(this);
    }

    handleSaveSearch() {
        this.props.createSavedSearch(this.props.searchTerm, "300");
        this.setState({searchSaved: true});
    }

    renderSaveSearchButton() {
        if(this.state.searchSaved) {
            return (
                <div className="search-saved"
                    id="save-search-button">
                    Search Saved
                </div>
            );
        }
        return (
            <div className="btn-solid"
                 id="save-search-button"
                 onClick={this.handleSaveSearch}>Save Search
            </div>
        );

    }

    render(){
        return (
            <div className="save-search-header">
                <h6 className="save-search-header-text">Results for</h6>
                <h3 className="save-search-header-text" id="save-search-search-term">{this.props.searchTerm}</h3>
                {this.renderSaveSearchButton()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    searchTerm: state.deals.searchTerm
});

export default connect(mapStateToProps, { createSavedSearch })(SaveSearchHeader);