import axios from "axios";

export function favoriteItemAPI(itemId) {
    return axios.post(`https://mishto.herokuapp.com/favorites`, {
        userId: localStorage.getItem('user_id'),
        deal: itemId,
    })
};

export function fetchFavoriteItemsAPI() {
    return axios.post(`https://mishto.herokuapp.com/v2/favorites/retrieve`, {
        userId: localStorage.getItem('user_id'),
        page: 1,
    })
};

export function fetchFavoriteItemIDsAPI() {
    return axios.post(`https://mishto.herokuapp.com/favorites/findAll`, {
        userId: localStorage.getItem('user_id')
    })
};