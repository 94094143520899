import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import editorsPicksSagas from './deals/editorspicks/saga';
import searchSagas from './deals/search/saga';
import favoritesSagas from './deals/favorites/saga';
import feedSagas from './deals/feed/saga';
import emailNewsletterSagas from './emailList/saga';


export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        editorsPicksSagas(),
        searchSagas(),
        favoritesSagas(),
        feedSagas(),
        emailNewsletterSagas(),
    ]);
}