import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {connect} from "react-redux";
import AccountInfo from "../../components/account/account-info"
import SavedSearches from "../../components/account/saved-searches"
class MyAccount extends Component {

    constructor (props) {
        super (props);

        this.showActiveComponent = this.showActiveComponent.bind(this);

        this.state = {
            activeComponent: props.activeComponent || "Account Info",
        }
    }

    render (){
        return (
            <div>
                <Breadcrumb title={this.state.activeComponent}/>


                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                {/*<div className="account-sidebar">*/}
                                {/*    <a className="popup-btn">*/}
                                {/*        my account*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li className="active"><a onClick={() => this.changeActiveComponent("Account Info")}>Account Info</a></li>
                                            <li><a onClick={() => this.changeActiveComponent("Saved Searches")}>My Saved Searches</a></li>
                                            <li><a href="#">Change Password</a></li>
                                            <li className="last"><a onClick={this.handleLogout}>Log Out</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        {this.showActiveComponent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }

    changeActiveComponent(newActiveComponent) {
        this.setState({activeComponent: newActiveComponent});
    }

    showActiveComponent(){
        switch (this.state.activeComponent) {
            case "Account Info":
                return <AccountInfo />;
            case "Saved Searches":
                return <SavedSearches />;
        }
    }
}

const mapStateToProps = ({ authUser }) => {
    const { user, userInfo } = authUser;
    return { user, userInfo };
};

export default connect(
    mapStateToProps, {}
)(MyAccount)

