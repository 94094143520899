import {put, call, takeLatest, all, fork} from 'redux-saga/effects';
import { fetchFeedItemsAPI } from "./service";


function* fetchFeedItems({ payload }) {
    const response = yield call(fetchFeedItemsAPI, payload);
    yield put({ type: "FEED_ITEMS_RECEIVED", items: response.data.deals, totalResultCount: response.data.totalResultCount, currentPage: payload.page});
    console.log("feed items response:", response);
}
function* watchFetchFeedItems() {
    yield takeLatest('GET_FEED_ITEMS', fetchFeedItems)
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchFeedItems),
    ]);
}