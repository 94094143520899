import React, {Component, Fragment} from 'react';
import {connect} from "react-redux";
import {getSavedSearches, getSearchResults, removeSavedSearch} from '../../redux/deals/search/actions';
import _ from "lodash";
import {Link} from "react-router-dom";



class  SavedSearches extends Component {

    constructor(props){
        super(props);

        this.state = {
            savedSearches: [],
            term: '',
        };

        this.renderSavedSearch = this.renderSavedSearch.bind(this);
        this.removeSavedSearch = this.removeSavedSearch.bind(this);
        this.viewAllSavedSearch = this.viewAllSavedSearch.bind(this);
        this.renderSearchBox = this.renderSearchBox.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.savedSearches != prevProps.savedSearches) {
            this.setState({savedSearches: this.props.savedSearches});
        }
    }

    componentDidMount() {
        this.props.getSavedSearches();
    }

    render(){
        return(
            <div className="box-account box-info">
                <div className="row">
                    <div className="col-sm-12">
                        <div id="add-saved-search-box" className="box">
                            <div id="add-saved-search-box-head" className="box-head">
                                <h2 id="add-saved-search-box-h2">Add a Saved Search</h2>
                            </div>
                            <div id="add-saved-search-box-title-no-underline" className="box-title">
                                <p id="add-saved-search-box-paragraph-no-underline">Start with a search, then refine by brand, category, size, color, and more.</p>
                            </div>
                            {this.renderSearchBox()}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="box">
                            <div className="box-head">
                                <h2>My Saved Searches</h2>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    {this.renderSavedSearch()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onInputChange(event) {
        this.setState({ term: event.target.value });
    }

    handleSearch() {
        this.props.getSearchResults(this.state.term, 1);
    }

    renderSearchBox(){
        return(
            <div  id="saved-search-box-container" className="home-search-box-container">
                <div id="saved-search-box-icon-container" className="home-search-box-icon-container">
                    <img
                        src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`}
                        className="home-search-box-icon" alt=""/>
                </div>
                <input id="saved-search-box-input" className="home-search-box-input"
                       type="text"
                       placeholder='Try "Fendi Purse"'
                       onChange={this.onInputChange}>
                </input>
                <Link id="home-search-box-button"
                      className="btn btn-solid"
                      onClick={this.handleSearch}
                      to={`${process.env.PUBLIC_URL}/search`}
                >search</Link>
            </div>
        )
    }


    renderSavedSearch(){
        const savedSearches = this.state.savedSearches.map((savedSearch) =>
           <Fragment key={savedSearch.id}>
                    <div className="box">
                        <div className="box-title">
                            <h3>{savedSearch.term}</h3>
                            <h6>Under {savedSearch.price}</h6>
                            <span>
                                <Link to={`${process.env.PUBLIC_URL}/search`}
                                      onClick={() => this.viewAllSavedSearch(savedSearch.term)}
                                >View All
                                </Link>
                                <span> | </span>
                                <a onClick={() => this.removeSavedSearch(savedSearch.id)}>Remove</a>
                            </span>
                        </div>
                    </div>
             </Fragment>
        );

        return (
            <Fragment>{savedSearches}</Fragment>
        )
    }

    viewAllSavedSearch(searchTerm){
        this.props.getSearchResults(searchTerm, 1);
    }

    removeSavedSearch(searchIDToRemove){
        this.props.removeSavedSearch(searchIDToRemove);
        let searchList = this.state.savedSearches;
        _.remove(searchList, function(n) {
            return n.id == searchIDToRemove;
        });
        this.setState({savedSearches: searchList});
    }
}

const mapStateToProps = ({ deals }) => {
    return { savedSearches: deals.savedSearches };
};

export default connect(
    mapStateToProps, { getSavedSearches, removeSavedSearch, getSearchResults }
)(SavedSearches)