export const getSearchResults = (searchTerm, page) => ({
    type: 'GET_SEARCH_RESULTS',
    payload: {
        searchTerm,
        page,
    }
});

export const getSavedSearches = () => ({
    type: 'GET_SAVED_SEARCHES',
});

export const removeSavedSearch = (savedSearchID) => ({
    type: 'REMOVE_SAVED_SEARCH',
    payload: savedSearchID,
});

export const createSavedSearch = (search, maxPrice) => ({
    type: 'CREATE_SAVED_SEARCH',
    payload: {
        search,
        maxPrice,
    },
});