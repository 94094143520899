import React, {Component} from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom';

import { addToCart, addToWishlist, addToCompare } from '../../../actions'
import Pagination from "./pagination";
import ProductListItem from "./product-list-item";
import { getEditorsPicks } from "../../../redux/deals/editorspicks/actions";
import { getSearchResults } from "../../../redux/deals/search/actions";
import { getFeedItems } from "../../../redux/deals/feed/actions";

class ProductListing extends Component {

    constructor (props) {
        super (props);

        this.state = {
            hasMoreItems: true,
        };

    }

    onPageChanged = data => {
        const { currentPage, totalPages, pageLimit } = data;
        if (currentPage != this.state.page){
            this.setState({page: currentPage});
            if (this.props.productType == 'EDITORS_PICKS') {
                this.props.getEditorsPicks(currentPage);
            } else if (this.props.productType == 'SEARCH') {
                this.props.getSearchResults(this.props.searchTerm, currentPage);
            } else if (this.props.productType == 'FEED') {
                this.props.getFeedItems(currentPage);
            }
        }


    };


    render (){
        if(this.props.loading){
            return null;
        }
        const {products, addToCart, symbol, addToWishlist, addToCompare, totalResultCount, currentPage} = this.props;
        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="container-fluid">
                        {products.length > 0 ?
                            <div className="row">
                                { products.map((product, index) =>
                                    <div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-grid-box':'col-lg-'+this.props.colSize}`} key={index}>
                                        <ProductListItem product={product} symbol={symbol}
                                                         onAddToCompareClicked={() => addToCompare(product)}
                                                         onAddToWishlistClicked={() => addToWishlist(product)}
                                                         onAddToCartClicked={addToCart} key={index}/>
                                    </div>)
                                }
                            </div>
                            :
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" />
                                    <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
                                    <p>Please check if you have misspelt something or try searching with other words.</p>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Pagination totalRecords={Number(totalResultCount)} pageNeighbours={1} onPageChanged={this.onPageChanged} currentPage={currentPage} />
            </div>
        )
    }
}
const mapStateToProps = ({ deals, data }) => {
    return {
        products: deals.products,
        symbol: data.symbol,
        loading: deals.editorsPicksLoading || deals.searchLoading || deals.favoritesLoading || deals.feedItemsLoading,
        productType: deals.productType,
        searchTerm: deals.searchTerm,
        totalResultCount: deals.totalResultCount,
        currentPage: deals.currentPage,
    }
};

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare, getEditorsPicks, getSearchResults, getFeedItems}
)(ProductListing)