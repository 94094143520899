import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import {connect} from "react-redux";
import {getUserInfo, loginUser, logoutUser} from "../../../../redux/auth/actions";
import _ from 'lodash';

class TopBar extends Component {

    constructor(){
        super();
        this.state = {}

    }

    componentDidMount() {
        if (localStorage.getItem('user_id') !== null &&
            !_.get(this.props, 'userInfo.first_name') &&
            !_.get(this.props, 'userInfo.last_name')) {
            this.props.getUserInfo();
        }
    }

    handleLogout(){
        this.props.logoutUser(this.props.history);
    }

    renderDropdown() {
        if (localStorage.getItem('user_id') !== null ) {
            return (
                <ul>
                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/my-account`} data-lng="en">My Account</Link>
                    </li>
                    <li>
                        <Link onClick={() => this.handleLogout()} to={`${process.env.PUBLIC_URL}/#`} data-lng="en">Sign out</Link>
                    </li>
                </ul>
                )
        } else {
            return (
                <ul>
                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/login`} data-lng="en">Login</Link>
                    </li>
                    <li>
                        <Link to={`${process.env.PUBLIC_URL}/pages/register`} data-lng="en">Register</Link>
                    </li>
                </ul>
            )
        }
    }

    renderUserHeader(translate){
        let firstName = _.get(this.props, 'userInfo.first_name');
        let lastName = _.get(this.props, 'userInfo.last_name');
        if (firstName && lastName){
            return <span id="my-account-span">{firstName} {lastName}</span>;
        } else {
            return <span id="my-account-span">{translate('my_account')}</span>;
        }
    }

    render() {
        const {translate} = this.props;
        return (
            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="header-contact">
                                <ul>
                                    <li>{translate('topbar_title', { theme_name: ' Multikart' })}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 text-right">
                            <ul className="header-dropdown">
                                <li className="onhover-dropdown mobile-account">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                    {this.renderUserHeader(translate)}
                                    <ul className="onhover-show-div">
                                        {this.renderDropdown()}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ authUser }) => {
    const { user, userInfo } = authUser;
    return { user, userInfo };
};

export default connect(
    mapStateToProps,
    {
        getUserInfo, loginUser, logoutUser

    }
)(withTranslate(TopBar));

