import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import Slider from "react-slick";
import {connect} from "react-redux";
import '../../common/index.scss';
import { getSearchResults } from '../../../redux/deals/search/actions';


class HomeSearch extends Component {

    constructor(){
        super();
        this.state = {
            term: '',
        };
        this.handleSearch = this.handleSearch.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.keyPressed = this.keyPressed.bind(this);
    }

    onInputChange(event) {
        this.setState({ term: event.target.value });
    }

    handleSearch() {
        let page = 1;
        this.props.getSearchResults(this.state.term, page);
    }

    keyPressed(event) {
        if (event.key === "Enter") {
            event.preventDefault();
            const { history } = this.props;
            history.push("/search");
            this.handleSearch();
        }
    }

    render() {
        return (
            <section className="p-0">
                <Slider className="slide-1 home-slider">
                    <div>
                        <div className="home home-mishto">
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="slider-contain">
                                            <div className="home-search-container">
                                                <h4 id="home-search-container-text">Search thousands of luxury resale
                                                    and
                                                    off price items</h4>
                                                <div className="home-search-box-container">
                                                    <div className="home-search-box-icon-container">
                                                        <img
                                                            src={`${process.env.PUBLIC_URL}/assets/images/icon/search.png`}
                                                            className="home-search-box-icon" alt=""/>
                                                    </div>
                                                    <input className="home-search-box-input"
                                                           type="text"
                                                           placeholder='Try "Fendi Purse"'
                                                           onChange={this.onInputChange}
                                                           onKeyPress={this.keyPressed}>
                                                    </input>
                                                    <Link id="home-search-box-button"
                                                       className="btn btn-solid"
                                                       onClick={this.handleSearch}
                                                       to={`${process.env.PUBLIC_URL}/search`}
                                                    >search</Link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
        );
    }
}

export default withRouter(connect(
    null, { getSearchResults }
)(HomeSearch));