import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGOUT_USER
} from '../../constants/actionTypes';

const INIT_STATE = {
    user: localStorage.getItem('user_id'),
    userInfo: {},
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, loading: true };
        case LOGIN_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload };
        case REGISTER_USER:
            return { ...state, loading: true };
        case REGISTER_USER_SUCCESS:
            return { ...state, loading: false, user: action.payload.uid };
        case LOGOUT_USER:
            return { ...state, user:null, userInfo:null};
        case 'GET_USER_INFO':
            return { ...state, loading: true };
        case 'USER_INFO_RECEIVED':
            return { ...state, userInfo: action.payload };
        default: return { ...state };
    }
}
