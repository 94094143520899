import {call, put, takeLatest, all, fork} from 'redux-saga/effects';
import {subscribeEmailAPI} from "./service";

function* subscribeEmail({ payload }) {
    const response = yield call(subscribeEmailAPI, payload);
    yield put({ type: "SUBSCRIBE_EMAIL_SUCCESS" });
}

function* watchSubscribeEmail() {
    yield takeLatest('SUBSCRIBE_EMAIL', subscribeEmail)
}

export default function* rootSaga() {
    yield all([
        fork(watchSubscribeEmail),
    ]);
}