import React, {Component} from 'react';
import { connect } from "react-redux";
import {withTranslate} from "react-redux-multilingual";
import Breadcrumb from "../common/breadcrumb";
import Slider from "react-slick";
import ImageZoom from "../products/common/product/image-zoom";
import SmallImages from "../products/common/product/small-image";
import DetailsWithPrice from "../products/common/product/details-price";
import DetailsTopTabs from "../products/common/details-top-tabs";
import RelatedProduct from "../common/related-product";
import FilterBar from "../collection/common/filter-bar";
import ProductListing from "../collection/common/product-listing";
import { getEditorsPicks } from "../../redux/deals/editorspicks/actions";
import { fetchFavoriteItemIDs } from "../../redux/deals/favorites/actions";

class EditorsPicks extends Component {

    state = {
        layoutColumns:3
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    componentWillMount(){
        this.props.getEditorsPicks(1);
        this.props.fetchFavoriteItemIDs();
    }

    render(){
        return (
            <div>
                <Breadcrumb title={'Editor\'s Picks'} parent={"Editor's Picks"} />

                {/*Section Start*/}
                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="collection-content col">
                                    <div className="page-main-content">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="top-banner-wrapper">
                                                        {/*<a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`}*/}
                                                        {/*                 className="img-fluid" alt=""/></a>*/}
                                                        <div id="top-banner-content" className="top-banner-content small-section">
                                                            <h4>Items we think you'll love.</h4>
                                                            <p>Curated by the editors at Shop Mishto</p>
                                                        </div>
                                                    </div>
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="product-wrapper-grid">
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <ProductListing colSize={this.state.layoutColumns}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Section End*/}

            </div>
        )
    }
}

const mapStateToProps = () => {
    // const { editorsPicks, loading } = editorsPicks;
    // return { editorsPicks, loading };
    return {} ;
};

export default connect(
    mapStateToProps,
    {
        getEditorsPicks, fetchFavoriteItemIDs,
    }
)(withTranslate(EditorsPicks));