import {call, put, takeLatest, all, fork} from 'redux-saga/effects';
import {fetchSearchResultsAPI, fetchSavedSearchesAPI, removeSavedSearchAPI, createSavedSearchAPI} from "./service";

function* fetchSearchResults({ payload }) {
    const response = yield call(fetchSearchResultsAPI, payload);
    yield put({ type: "SEARCH_RESULTS_RECEIVED", searchResults: response.data.deals.deals, totalResultCount: response.data.totalResultCount, currentPage: payload.page });
    // }catch(e) {
    //     console.log("search result error");
    //     yield put({ type: "SEARCH_RESULTS_ERROR", payload: e.message })
    // }
}
function* watchFetchSearchResults() {
    yield takeLatest('GET_SEARCH_RESULTS', fetchSearchResults)
}

function* fetchSavedSearches({ payload }) {
    const response = yield call(fetchSavedSearchesAPI, payload);
    yield put({ type: "SAVED_SEARCHES_RECEIVED", savedSearches: response.data.searches });
    // }catch(e) {
    //     console.log("search result error");
    //     yield put({ type: "SAVED_SEARCHES_ERROR", payload: e.message })
    // }
}
function* watchFetchSavedSearches() {
    yield takeLatest('GET_SAVED_SEARCHES', fetchSavedSearches)
}

function* removeSavedSearch({ payload }) {
    const response = yield call(removeSavedSearchAPI, payload);
    yield put({ type: "REMOVED_SAVED_SEARCH_SUCCESS"});
    // }catch(e) {
    //     console.log("search result error");
    //     yield put({ type: "SAVED_SEARCHES_ERROR", payload: e.message })
    // }
}
function* watchRemoveSavedSearch() {
    yield takeLatest('REMOVE_SAVED_SEARCH', removeSavedSearch)
}

function* createSavedSearch({ payload }) {
    const response = yield call(createSavedSearchAPI, payload);
    yield put({ type: "CREATE_SAVED_SEARCH_SUCCESS"});
    // }catch(e) {
    //     console.log("save search error");
    //     yield put({ type: "CREATE_SAVED_SEARCH_ERROR", payload: e.message })
    // }
}
function* watchCreateSavedSearch() {
    yield takeLatest('CREATE_SAVED_SEARCH', createSavedSearch)
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchSearchResults),
        fork(watchFetchSavedSearches),
        fork(watchRemoveSavedSearch),
        fork(watchCreateSavedSearch),
    ]);
}