
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { auth } from '../../firebase';
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER
} from '../../constants/actionTypes';

import {
    loginUserSuccess,
    registerUserSuccess
} from './actions';
import axios from "axios/index";
import { fetchUserInfoAPI } from "./service";
import {fetchFavoriteItemIDs} from "../deals/favorites/actions";

const loginWithEmailPasswordAsync = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);



function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    console.log("loginWithEmailPassword");
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if (!loginUser.message) {
            localStorage.setItem('user_id', loginUser.user.uid);
            yield put(loginUserSuccess(loginUser));
            history.push('/');
            fetchFavoriteItemIDs();
        } else {
            // catch throw
            console.log('login failed :', loginUser.message)
        }
    } catch (error) {
        // catch throw
        console.log('login error : ', error)
    }
}

const postUser = async () => {
    console.log("Posting user to backend");
    axios.post(`https://mishto.herokuapp.com/users`, {id: localStorage.getItem('user_id'), email: localStorage.getItem('email'), firstName: localStorage.getItem('first_name'), lastName: localStorage.getItem('last_name')})
        .then(res => {
            console.log(res);
        })
}

const registerWithEmailPasswordAsync = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload
    console.log("registerWithEmailPassword")
    try {
        const registerUser = yield call(registerWithEmailPasswordAsync, email, password);
        if (!registerUser.message) {
            localStorage.setItem('user_id', registerUser.user.uid);
            postUser();
            yield put(registerUserSuccess(registerUser));
            history.push('/app/dashboards/analytics');

        } else {
            // catch throw
            console.log('register failed :', registerUser.message)
        }
    } catch (error) {
        // catch throw
        console.log('register error : ', error)
    }
}



const logoutAsync = async (history) => {
    await auth.signOut().then(authUser => authUser).catch(error => error);
    history.push('/')
}

function* logout({payload}) {
    const { history } = payload
    try {
        localStorage.removeItem('user_id');
        yield call(logoutAsync,history);
    } catch (error) {
    }
}

function* fetchUserInfo() {
    const response = yield call(fetchUserInfoAPI);
    yield put({type: "USER_INFO_RECEIVED", payload: response.data, })
}

function* watchGetUserInfo() {
    yield takeLatest('GET_USER_INFO', fetchUserInfo);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchGetUserInfo),
    ]);
}