import React, { Component } from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';

// Import custom components
import {
    svgFreeShipping,
    svgservice,
    svgoffer,
    svgpayment
} from "../../../services/script"
import TopCollection from "../common/collection"
import NewProduct from "../../common/new-product"
import Instagram from "../common/instagram"
import HeaderOne from "../../common/headers/header-one"
import FooterOne from "../../common/footers/footer-one"
import BlogSection from "../common/blogsection";
import HomeSearch from "./home-search";

class MishtoHome extends Component {
    constructor(props){
        super(props)

        this.state = {
            open: false
        }
    }
    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color6.css` );
    }

    render(){

        return (
            <div>
                <Helmet>
                    <title>SHOP MISHTO</title>
                </Helmet>
                <HeaderOne logoName={'layout3/logo.png'}/>

                {/*Home Search Section*/}
                <HomeSearch/>

                {/*About Section*/}
                <section className="beauty-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 col-md-12 offset-xl-1 text-center">
                                <img id="green-suit-woman-img" src={`${process.env.PUBLIC_URL}/assets/images/mishto/green-suit-woman.jpg`} alt="" className="img-fluid blur-up lazyload" />
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-12">
                                <div className="about-section">
                                    <div>
                                        <h2 id="about-section-header">Your Style Revolution Awaits</h2>
                                        <div className="about-text">
                                            <p id="about-section-text">Fast Fashion is out. Shop Mishto helps you find your personal style by providing access to the best deals on resale and off-price fashion.</p>
                                        </div>
                                        <div className="service small-section pb-0">
                                            <div className="row">
                                                <div className="service-block1">
                                                    <div className="service-block-icon-container">
                                                        <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                                    </div>
                                                    <p className="service-section-paragraph">Use the search tool to find your most coveted items at discounted prices. </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="service-block1">
                                                    <div className="service-block-icon-container">
                                                        <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                                    </div>
                                                    <p className="service-section-paragraph">Like what you’re seeing? Save your search. We’ll let you know when items matching
                                                        your term are available.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="service-block1">
                                                    <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                                    <p className="service-section-paragraph">All your saved searches make up your personalized shopping feed. Browse away.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*About Section End*/}

                {/*Product slider*/}
                <TopCollection type={'beauty'} />
                {/*Product slider End*/}

                {/*Blog Section*/}
                {/*<div className="container">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col">*/}
                {/*            <div className="title1">*/}
                {/*                <h4>Get Inspired</h4>*/}
                {/*                <h2 className="title-inner1">from the blog</h2>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<section className="blog p-t-0 ratio3_2">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-md-12">*/}
                {/*                <BlogSection />*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*Blog Section end*/}


                {/*Instagram Section*/}
                <div className="section-b-space">
                    <Instagram type="watch" />
                </div>
                {/*Instagram Section End*/}

                <FooterOne logoName={'layout3/logo.png'}/>
            </div>
        )
    }
}


export default MishtoHome;