import React from 'react';
import { connect } from 'react-redux'

let Loading = ({ loading }) => (
    loading ?
        <div className="loader-wrapper">
            <div className="loader"></div>
        </div>:
        null
);
const mapStateToProps = ({deals}) => ({loading: deals.editorsPicksLoading || deals.searchLoading});
Loading = connect(mapStateToProps,null)(Loading);
export default Loading;