const reducer = (state = {}, action) => {
    switch (action.type) {

        // FEED ITEMS
        case 'GET_FEED_ITEMS':
            return { ...state,
                products: [],
                feedItemsLoading: true,
                favoritesLoading: false,
                searchTerm: null,
                searchLoading: false,
                editorsPicksLoading: false,
                productType: 'FEED' };
        case 'FEED_ITEMS_RECEIVED':
            return { ...state, products: action.items, feedItemsLoading: false, searchTerm: null, productType: 'FEED', totalResultCount: action.totalResultCount, currentPage: action.currentPage };

        // EDITORS PICKS
        case 'GET_EDITORS_PICKS':
            return { ...state, products: [], editorsPicksLoading: true, searchTerm: null, searchLoading: false,  productType: 'EDITORS_PICKS' };
        case 'EDITORS_PICKS_RECEIVED':
            return { ...state, products: action.picks, editorsPicksLoading: false, searchTerm: null, productType: 'EDITORS_PICKS', totalResultCount: action.totalResultCount, currentPage: action.currentPage };

        // SEARCH RESULTS
        case 'GET_SEARCH_RESULTS':
            return { ...state, products: [], searchTerm: action.payload.searchTerm, searchLoading: true,  editorsPicksLoading: false,  productType: 'SEARCH', totalResultCount: 0};
        case 'SEARCH_RESULTS_RECEIVED':
            return { ...state, products: action.searchResults, searchLoading: false, productType: 'SEARCH', totalResultCount: action.totalResultCount, currentPage: action.currentPage };

        // FAVORITES
        case 'TOGGLE_FAVORITE':
            return { ...state};
        case 'FETCH_FAVORITE_ITEMS':
            return { ...state, products: [], favoritesLoading: true, productType: 'FAVORITES'};
        case 'FAVORITE_ITEMS_RECEIVED':
            return { ...state, products: action.favorites || [], favoritesLoading: false, productType: 'FAVORITES'};
        case 'FETCH_FAVORITE_ITEM_IDS':
            return { ...state, favoriteItemIDsLoading: true};
        case 'FAVORITE_ITEM_IDS_RECEIVED':
            return { ...state, favoriteIDs: action.favorite_ids, favoriteItemIDsLoading: false};

        // SAVED SEARCHES
        case 'GET_SAVED_SEARCHES':
            return { ...state, savedSearchesLoading: true, savedSearches: [] };
        case 'SAVED_SEARCHES_RECEIVED':
            return { ...state, savedSearchesLoading: false, savedSearches: action.savedSearches };
        case 'REMOVE_SAVED_SEARCH':
            return { ...state };

        // EMAIL NEWSLETTER
        case 'SUBSCRIBE_EMAIL':
            return { ...state };

        default:
            return state;
    }
};
export default reducer;