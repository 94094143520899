import React, {Component} from 'react';
import { connect } from "react-redux";
import {withTranslate} from "react-redux-multilingual";
import Breadcrumb from "../common/breadcrumb";
import FilterBar from "../collection/common/filter-bar";
import ProductListing from "../collection/common/product-listing";
import { getFeedItems } from "../../redux/deals/feed/actions";
import { fetchFavoriteItemIDs } from "../../redux/deals/favorites/actions";


class Feed extends Component {

    state = {
        layoutColumns:3
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    componentWillMount(){
        this.props.getFeedItems(1);
        this.props.fetchFavoriteItemIDs();
    }

    render(){
        return (
            <div>
                <Breadcrumb title={'My Feed'} parent={"My Feed"} />

                {/*Section Start*/}
                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="collection-content col">
                                    <div className="page-main-content">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="top-banner-wrapper">
                                                        <div id="top-banner-content" className="top-banner-content small-section">
                                                            <h4>Items from your saved searches.</h4>
                                                        </div>
                                                    </div>
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="product-wrapper-grid">
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <ProductListing colSize={this.state.layoutColumns}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Section End*/}

            </div>
        )
    }
}

const mapStateToProps = () => {
    return {} ;
};

export default connect(
    mapStateToProps,
    {
        getFeedItems, fetchFavoriteItemIDs,
    }
)(withTranslate(Feed));