import React, {Component} from 'react';
import { connect } from 'react-redux'
import {toggleFavorite} from '../../../redux/deals/favorites/actions'
import _ from 'lodash';

class FavoriteHeart extends Component {

    constructor(){
        super();
        this.handleToggle = this.handleToggle.bind(this);
        this.state =  {};
    }

    componentDidMount() {
        this.setState({favoritesList: this.props.favoritesList});
    }

    handleToggle() {
        let favoritesList = this.state.favoritesList;
        if(_.includes(favoritesList, this.props.itemId)){
            _.pull(favoritesList, this.props.itemId)
        } else
        {
            favoritesList.push(this.props.itemId)
        }
        this.setState({favoritesList})
        this.props.toggleFavorite(this.props.itemId);
    }

    render (){
        if(_.includes(this.state.favoritesList, this.props.itemId)) {
            return (
                <a  id={this.props.itemId} title="Unfavorite" onClick={this.handleToggle} >
                    <img className="like-button"
                         src={`${process.env.PUBLIC_URL}/assets/images/filled_heart.png`}>
                    </img>
                </a>
            )
        } else {
            return (
                <a  id={this.props.itemId} title="Favorite" onClick={this.handleToggle} >
                <img className="like-button"
                     src={`${process.env.PUBLIC_URL}/assets/images/open_heart.png`}>
                </img>
                </a>
            )
        }
    }
}

const mapStateToProps = ({deals}) => {
    return {favoritesList: deals.favoriteIDs}
};

export default connect(mapStateToProps, {toggleFavorite})(FavoriteHeart);