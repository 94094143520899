import React, {Component} from 'react';
import { Link} from 'react-router-dom';

import {SlideUpDown} from "../../../services/script"
import { subscribeEmail } from "../../../redux/emailList/action"
import {connect} from "react-redux";

class FooterOne extends Component {

    constructor(props){
        super(props);
        this.state = {
            email: '',
            subscribeSuccess: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }

    handleChange(event) {
        this.setState({email: event.target.value});
    }

    handleSubmit(event){
        event.preventDefault();
        this.props.subscribeEmail(this.state.email);
        this.setState({email:'', subscribeSuccess: true});
    }

    renderSubscribeSuccess(){
        return (
            <div className="col-lg-6">
                <div className="mx-sm-3 btn subscribe-form">
                    <p>you are successfully subscribed!</p>
                </div>
             </div>
        )
    }


    render () {

        return (
            <footer className="footer-light">
                <div className="light-layout">
                    <div className="container">
                        <section className="small-section border-section border-top-0">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="subscribe">
                                        <div>
                                            <h4>KNOW IT ALL FIRST!</h4>
                                            <p>Never Miss Anything From Shop Mishto By Signing Up To Our Newsletter. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <form className="form-inline subscribe-form" onSubmit={this.handleSubmit}>
                                        <div className="form-group mx-sm-3">
                                            <input type="text"
                                                   className="form-control"
                                                   id="exampleFormControlInput1"
                                                   placeholder="Enter your email"
                                                   onChange={this.handleChange}
                                                   value={this.state.email}
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-solid"
                                        >subscribe</button>
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6"></div>
                                {this.state.subscribeSuccess ? this.renderSubscribeSuccess() : null}
                            </div>
                        </section>
                    </div>
                </div>
                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row footer-theme partition-f">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-title footer-mobile-title">
                                    <h4>about</h4>
                                </div>

                                <div className="sub-title footer-title">
                                    <h4>Follow us on social media</h4>
                                </div>

                                <div className="footer-contant">
                                    {/*<div className="footer-logo">*/}
                                    {/*<LogoImage logo={this.props.logoName} />*/}
                                    {/*</div>*/}
                                    <div className="footer-social">
                                        <ul>
                                            <li>
                                                <Link to={'https://www.facebook.com/'} ><i className="fa fa-facebook" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://plus.google.com/'} ><i className="fa fa-google-plus" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={'https://twitter.com'}><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/shopmishto/?hl=en" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
                                            </li>
                                            <li>
                                                <Link to={'https://rss.com/'}><i className="fa fa-rss" aria-hidden="true"></i></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="col offset-xl-1">*/}
                            {/*    <div className="sub-title">*/}
                            {/*        <div className="footer-title">*/}
                            {/*            <h4>my account</h4>*/}
                            {/*        </div>*/}
                            {/*        <div className="footer-contant">*/}
                            {/*            <ul>*/}
                            {/*                <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >my feed</Link></li>*/}
                            {/*                <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >my favorites</Link></li>*/}
                            {/*                <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >my saved searches</Link></li>*/}
                            {/*                <li><Link to={`${process.env.PUBLIC_URL}/left-sidebar/collection`} >featured</Link></li>*/}
                            {/*            </ul>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="col offset-xl-1">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>information</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul className="info-pages">
                                          <li><Link to={`${process.env.PUBLIC_URL}/about`} >about us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="sub-title">
                                    <div className="footer-title">
                                        <h4>contact</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul className="contact-list">
                                            <li><i className="fa fa-envelope-o"></i>Email Us: <a id="email-link"
                                                                                                 href="mailto:hello@shopmishto.com">hello@shopmishto.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<div className="sub-footer ">*/}
                {/*    <div className="container">*/}
                {/*        <div className="row">*/}
                {/*            <div className="col-xl-6 col-md-6 col-sm-12">*/}
                {/*                <div className="footer-end">*/}
                {/*                    <p><i className="fa fa-copyright" aria-hidden="true"></i> 2018-19 themeforest*/}
                {/*                        powered by pixelstrap</p>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <div className="col-xl-6 col-md-6 col-sm-12">*/}
                {/*                <div className="payment-card-bottom">*/}
                {/*                    <ul>*/}
                {/*                        <li>*/}
                {/*                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/visa.png`} alt="" /></a>*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/mastercard.png`} alt="" /></a>*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/paypal.png`} alt="" /></a>*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/american-express.png`} alt="" /></a>*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/icon/discover.png`} alt="" /></a>*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </footer>
        )
    }
}

export default connect(null, { subscribeEmail })(FooterOne);