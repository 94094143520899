import React, {Component} from 'react';

import Breadcrumb from "../common/breadcrumb";

import { connect } from "react-redux";
import { loginUser } from "../../redux/actions";
import {Link} from "react-router-dom";

class Login extends Component {

    constructor (props) {
        super (props)
        this.state = {
            email: "",
            password: "",
        };
    }

    onUserLogin() {
        console.log(this.state);
        if (this.state.email !== "" && this.state.password !== "") {
            this.props.loginUser(this.state, this.props.history);
        }
    }

    render (){


        return (
            <div>
                <Breadcrumb title={'Login'}/>
                
                
                {/*Login section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Login</h3>
                                <div className="theme-card">
                                    <form className="theme-form">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" className="form-control" id="email" placeholder="Email"
                                                   required="" defaultValue={this.state.email}
                                                   onChange={e => this.setState({email: e.target.value})}/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="review">Password</label>
                                            <input type="password" className="form-control" id="review"
                                                   placeholder="Enter your password" required=""
                                                   onChange={e => this.setState({password: e.target.value})} />
                                        </div>
                                        <a className="btn btn-solid" onClick={() => this.onUserLogin()}>Login</a>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <h3>New Customer</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-font">Create An Account</h6>
                                    <p>Sign up for a free account!</p>
                                    <Link className="btn btn-solid" to={`${process.env.PUBLIC_URL}/pages/register`} data-lng="en">Create an Account</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = ({ authUser }) => {
    const { user, loading } = authUser;
    return { user, loading };
};

export default connect(
    mapStateToProps,
    {
        loginUser
    }
)(Login);
