import {put, call, takeLatest, all, fork} from 'redux-saga/effects';
import { fetchEditorsPicksAPI } from "./service";


function* fetchEditorsPicks({ payload }) {
    const response = yield call(fetchEditorsPicksAPI, payload);
    yield put({ type: "EDITORS_PICKS_RECEIVED", picks: response.data.deals, totalResultCount: response.data.totalResultCount, currentPage: payload.page});
}
function* watchFetchEditorsPicks() {
    yield takeLatest('GET_EDITORS_PICKS', fetchEditorsPicks)
}

export default function* rootSaga() {
    yield all([
        fork(watchFetchEditorsPicks),
    ]);
}